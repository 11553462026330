/* eslint-disable no-undef */
import {
	Flex,
	HStack,
	useColorModeValue as mode,
	Button,
	Image,
	Text,
} from "@chakra-ui/react";
import { Logo } from "../Logo";
import { MobileHamburgerMenu } from "./MobileHamburgerMenu";
import { NavMenu } from "./NavMenu";
import { ProfileDropdown } from "./ProfileDropdown";
import { useMobileMenuState } from "./useMobileMenuState";
import { useEffect } from "react";
import { useState } from "react";
import axios from "axios";
import { Banner } from "../components/Banner";

import { WalletConnectConnector } from "@web3-react/walletconnect-connector";
import { InjectedConnector } from "@web3-react/injected-connector";
import { useWeb3React } from "@web3-react/core";

const WalletConnect = new WalletConnectConnector({
	rpc: {137: process.env.NEXT_PUBLIC_ALCHEMY_API_URL as string },
	bridge: "https://bridge.walletconnect.org",
	qrcode: true,

   });
   
   const Injected = new InjectedConnector({});

export default function Shell(props: any) {
	const { isMenuOpen, toggle } = useMobileMenuState();
	const [raiderPrice, setRaiderPrice] = useState<number>(0);
	const [aurumPrice, setAurumPrice] = useState<number>(0);
	const { activate, deactivate } = useWeb3React();
	const { active, chainId, library, account } = useWeb3React();
	const [onPolygon, setOnPolygon] = useState<boolean>(true);
	
	const bannerMessage = "Please switch to the Polygon network.";
	useEffect(() => {
		const fetchData = async () => {
			const raider = await axios
				.get(
					"https://api.coingecko.com/api/v3/simple/price?ids=crypto-raiders&vs_currencies=usd"
				)
				.then((res) => {
					return res.data["crypto-raiders"].usd;
				});

			const aurum = await axios
				.get(
					"https://api.coingecko.com/api/v3/simple/price?ids=raider-aurum&vs_currencies=usd"
				)
				.then((res) => {
					return res.data["raider-aurum"].usd;
				});

			setRaiderPrice(raider);
			setAurumPrice(aurum);
		};

		fetchData();
	}, []);

	useEffect(() => {
		setOnPolygon(!active || chainId == 137);
		(window as any).current_provider = active ? library.provider : null;
	},[chainId,active, account])

	return (
		<Flex direction="column" bg={mode("gray.100", "gray.800")} height="100vh">
			<Banner message={bannerMessage} display={!onPolygon} />

			<Flex align="center" bg="gray.900" color="white" px="6" minH="16">
				<Flex justify="space-between" align="center" w="full">
					<MobileHamburgerMenu onClick={toggle} isOpen={isMenuOpen} />
					<NavMenu.Mobile isOpen={isMenuOpen} />

					{/* Desktop Logo placement */}
					<Logo
					// display={{ base: "none", lg: "block" }}
					// flexShrink={0}
					// h="5"
					// marginEnd="10"
					/>

					{/* Desktop Navigation Menu */}
					<NavMenu.Desktop />

					<HStack spacing="3">
						<Button
							as="a"
							className="menuButton"
							href="https://polygon.sushi.com/swap?inputCurrency=ETH&outputCurrency=0x34d4ab47Bee066F361fA52d792e69AC7bD05ee23"
							target="_blank"
						>
							Buy AURUM ${Number(aurumPrice).toFixed(3)}
						</Button>
						<Button
							as="a"
							className="menuButton"
							href="https://polygon.sushi.com/swap?inputCurrency=ETH&outputCurrency=0xcd7361ac3307D1C5a46b63086a90742Ff44c63B3"
							target="_blank"
						>
							Buy RAIDER ${Number(raiderPrice).toLocaleString()}
						</Button>
						<Button
							className="menuButton"
							id="play-button"
							target="_blank"
							href="https://play.cryptoraiders.gg/"
							backgroundColor="green"
						>
							<Flex
								justifyContent="space-between"
								as="a"
								href="https://play.cryptoraiders.gg/"
							>
								<Image src="/favicon.ico" alt="CR Logo" width="5" height="5" />
								<Text ml="3">Play</Text>
							</Flex>
						</Button>
						{/* <Notification display={{ base: 'none', lg: 'inline-flex' }} /> */}
						{!active && (
							<span>
								<Button  onClick={() => { activate(WalletConnect) }} marginRight={2}>Wallet Connect</Button>
								<Button  onClick={() => { activate(Injected) }}>Metamask</Button>
							</span>

						)}
						{active && (
							<button onClick={deactivate}>Disconnect</button>
						)}
						<ProfileDropdown active={active} wc_func={() => { activate(WalletConnect) }} mm_func={() => { activate(Injected) }} deactivate={deactivate} />
					</HStack>
				</Flex>
			</Flex>
			{props.children}
		</Flex>
	);
}
