/* eslint-disable no-unused-vars */
const ALCHEMY_API_URL = process.env.NEXT_PUBLIC_ALCHEMY_API_URL || "";
const AURUM_CONTRACT_ADDRESS = process.env.NEXT_PUBLIC_AURUM;
const RAIDER_CONTRACT_ADDRESS = process.env.NEXT_PUBLIC_RAIDER;
const RAIDER_STAKING_LOCKING_CONTRACT_ADDRESS =
	process.env.NEXT_PUBLIC_STAKINGLOCKING;

import { AbiItem } from "web3-utils";
import { ethers } from "ethers";
import axios from "axios";

import Aurum from "../../contracts/Aurum.json";
import Raider from "../../contracts/Raider.json";
import Raiders from "../../contracts/Raiders.json";
import RaiderStaking from "../../contracts/Staking.json";
import RaiderStakingLocking from "../../contracts/StakingLocking.json";
import Material from "../../contracts/Material.json";

import {
	UseToastOptions,
} from "@chakra-ui/toast";

import { createAlchemyWeb3 } from "@alch/alchemy-web3";
import { Raider as RaiderType } from "../../types/raider";
import { Mount } from "../../types/mount";
declare var window: any;

const web3 = createAlchemyWeb3(ALCHEMY_API_URL);

const aurumContract = new web3.eth.Contract(
	Aurum.abi as AbiItem[],
	AURUM_CONTRACT_ADDRESS
);

const raiderContract = new web3.eth.Contract(
	Raider.abi as AbiItem[],
	RAIDER_CONTRACT_ADDRESS
);

const raiderCharContract = new web3.eth.Contract(
	Raiders.abi as AbiItem[],
	process.env.NEXT_PUBLIC_RAIDER_CHARACTERS
);

const grimweedContract = new web3.eth.Contract(
	Material.abi as AbiItem[],
	process.env.NEXT_PUBLIC_GRIMWEED
);

const newtContract = new web3.eth.Contract(
	Material.abi as AbiItem[],
	process.env.NEXT_PUBLIC_NEWT
);

const sporebarkContract = new web3.eth.Contract(
	Material.abi as AbiItem[],
	process.env.NEXT_PUBLIC_SPOREBARK
);

const mhpContract = new web3.eth.Contract(
	Material.abi as AbiItem[],
	process.env.NEXT_PUBLIC_MINOR_HEALTH_POTION
);

const bhpContract = new web3.eth.Contract(
	Material.abi as AbiItem[],
	process.env.NEXT_PUBLIC_BASIC_HEALTH_POTION
);

const mdpContract = new web3.eth.Contract(
	Material.abi as AbiItem[],
	process.env.NEXT_PUBLIC_MINOR_DAMAGE_POTION
);

const bdpContract = new web3.eth.Contract(
	Material.abi as AbiItem[],
	process.env.NEXT_PUBLIC_BASIC_DAMAGE_POTION
);

const raiderStakingContract = (pool: string) => {
	return new web3.eth.Contract(RaiderStaking.abi as AbiItem[], pool);
};

const lpTokenContract = (pool: string) => {
	return new web3.eth.Contract(Raider.abi as AbiItem[], pool);
};

const raiderStakingLockingContract = new web3.eth.Contract(
	RaiderStakingLocking.abi as AbiItem[],
	RAIDER_STAKING_LOCKING_CONTRACT_ADDRESS
);

export const getAccount = async () => {
	if ((window as any).current_provider) {
		web3.setWriteProvider((window as any).current_provider)
		if ((window as any).current_provider.isMetaMask) {
			//metamask
			return (window as any).current_provider.selectedAddress
		} else {
			return (window as any).current_provider.accounts[0];
		}
	} 
};

//

export const connectWallet = async (
	toast: (options: UseToastOptions) => string | number | undefined
) => {
	if (window.ethereum) {
		try {
			const addressArray = await window.ethereum.request({
				method: "eth_requestAccounts",
			});
			if (addressArray[0]) {
				toast({
					title: "Connected!",
					description: "You are now connected",
					status: "success",
					duration: 9000,
					position: "top",
					isClosable: true,
				});
			}
		} catch (err: any) {
			toast({
				title: "Couldn't connect...",
				description: err.message,
				status: "error",
				duration: 9000,
				position: "top",
				isClosable: true,
			});
		}
	} else {
		toast({
			title: "Couldn't connect...",
			description:
				"Are you sure you have MetaMask installed and you're on the Polygon network?",
			status: "error",
			duration: 9000,
			position: "top",
			isClosable: true,
		});
	}
};

// ---------- ALL TOKENS ----------

export async function tokenAllowanceCheck(token: string, spender: string) {
	const account = await getAccount();
	const contract = tokenResolver(token);
	try {
		const result = await contract.methods.allowance(account, spender).call();
		return ethers.utils.formatEther(result);
	} catch (err) {
		console.log(err);
	}
}

export async function directTokenAllowanceCheck(address: string, spender: string) {
	const account = await getAccount();

	const contract = new web3.eth.Contract(
		Material.abi as AbiItem[],
		address
	);
	try {
		const result = await contract.methods.allowance(account, spender).call();
		return ethers.utils.formatEther(result);
	} catch (err) {
		console.log(err);
	}
}

export async function directTokenApproval(address: string, spender: string,	toast: (options: UseToastOptions) => string | number | undefined
) {
	const account = await getAccount();

	const contract = new web3.eth.Contract(
		Material.abi as AbiItem[],
		address
	);
	try {
		const result = await contract.methods
			.approve(spender, ethers.constants.MaxUint256)
			.send({ from: account, maxPriorityFeePerGas: null,
			maxFeePerGas: null }, function (err: any, res: any) {
				if (err) {
					toast({
						title: "Couldn't approve your token...",
						description: err.message,
						status: "error",
						duration: 9000,
						position: "top",
						isClosable: true,
					});
					console.log(err);
				}
				console.log("Hash of the transaction: " + res);
			});
		return result;
	} catch (err) {
		console.log(err);
	}
}


export async function tokenApproval(
	token: string,
	spender: string,
	toast: (options: UseToastOptions) => string | number | undefined
) {
	const account = await getAccount();
	const contract = tokenResolver(token);
	try {
		const result = await contract.methods
			.approve(spender, "1000000000000000000000000000")
			.send({ from: account, maxPriorityFeePerGas: null,
			maxFeePerGas: null }, function (err: any, res: any) {
				if (err) {
					toast({
						title: "Couldn't approve your token...",
						description: err.message,
						status: "error",
						duration: 9000,
						position: "top",
						isClosable: true,
					});
					console.log(err);
				}
				console.log("Hash of the transaction: " + res);
			});
		return result;
	} catch (err) {
		console.log(err);
	}
}

// ---------- AURUM ----------

export async function aurumBalance() {
	const account = await getAccount();
	try {
		const result = await aurumContract.methods.balanceOf(account).call();
		return ethers.utils.formatEther(result);
	} catch (err) {
		return "0";
	}
}

// ---------- RAIDER ----------

export async function raiderBalance() {
	const account = await getAccount();
	try {
		const result = await raiderContract.methods.balanceOf(account).call();
		return ethers.utils.formatEther(result);
	} catch (err) {
		return "0";
	}
}

// ---------- RAIDERS ----------

export async function ownedRaidersIds(): Promise<number[]> {
	const account = await getAccount();
	try {
		const result = await raiderCharContract.methods
			.raidersOwnedBy(account)
			.call();
		return result;
	} catch (err) {
		return []
	}
}

//https://www.w3resource.com/javascript-exercises/fundamental/javascript-fundamental-exercise-265.php
export const chunk = (arr: Array<any>, size: number) =>
  Array.from({ length: Math.ceil(arr.length / size) }, (v, i) =>
    arr.slice(i * size, i * size + size)
  );

export async function getManyEntities(token_ids: number[], entity_type: 'raiders' | 'mounts'): Promise<Array<RaiderType | Mount>>   {
	if (!token_ids.length) {
		return [];
	}
	
	let entities = [] as Array<RaiderType | Mount>;

	const calls = chunk(token_ids, 50).map(async raiderSet => {
		const params = new URLSearchParams();
		raiderSet.forEach(token_id => {
			params.append('ids[]', token_id);
		})

		return axios.get(`https://api.cryptoraiders.xyz/${entity_type}`, {params: params})
	})

	await Promise.all(calls).then(calls => {
		calls.forEach(resp => {
			entities = entities.concat(resp.data)
		})
	})
	return entities;

}


export async function ownedRaiders() {
	const account = await getAccount();
	if (account) {
		const result = await raiderCharContract.methods
		.raidersOwnedBy(account)
		.call() as number[]

		if (result.length) {
			return await getManyEntities(result, 'raiders')
		} else {
			return []
		}
	} else {
		console.log('shouldnt be calling ownedRaiders')
		return []
	}
}

// ---------- MATERIALS ----------

const materialResolver = (name: string) => {
	if (name == "GRIMWEED") {
		return grimweedContract;
	} else if (name == "EYE OF NEWT" || name == "NEWT") {
		return newtContract;
	} else if (name == "MHP") {
		return mhpContract;
	} else if (name == "BHP") {
		return bhpContract;
	}  else if (name == "MDP") {
		return mdpContract;
	} else if (name == "BDP") {
		return bdpContract;
	} else if (name === "SPOREBARK") {
		return sporebarkContract
	} else if (name === "AURUM") {
		return aurumContract
	} else {
		throw `${name} material contract not defined`
	}
};

export async function materialBalance(name: string) {
	const account = await getAccount();
	if (account) {
		const contract = materialResolver(name);
		try {
			const result = await contract.methods.balanceOf(account).call();
			return result;
		} catch (err) {
			console.log(err);
		}
	} else {
		return 0
	}
}

// ---------- CLAIM ALL ----------

export async function claimAllRewards(
	toast: (options: UseToastOptions) => string | number | undefined
) {
	if (parseInt(await stakingLockingRewards()) > 0) {
		getLockedRewards(toast);
	}

	if (parseInt(await stakingLPRewards("RAIDER/WETH")) > 0) {
		getLPRewards("RAIDER/WETH", toast);
	}
	if (parseInt(await stakingLPRewards("RAIDER/MATIC")) > 0) {
		getLPRewards("RAIDER/MATIC", toast);
	}
	if (parseInt(await stakingLPRewards("AURUM/MATIC")) > 0) {
		getLPRewards("AURUM/MATIC", toast);
	}
	if (parseInt(await stakingLPRewards("AURUM/USDC")) > 0) {
		getLPRewards("AURUM/USDC", toast);
	}
}

// ---------- STAKING LOCKING POOL ----------

export async function stakingLockingBalance() {
	const account = await getAccount();
	if (account) {
		try {
			const result = await raiderStakingLockingContract.methods
				.addressStakedBalance(account)
				.call();
			return ethers.utils.formatEther(result);
		} catch (err) {
			console.log(err);
		}
	}
}

export async function totalStakedSupply() {
	try {
		const result = await raiderStakingLockingContract.methods
			.totalStakedSupply()
			.call();
		return ethers.utils.formatEther(result);
	} catch (err) {
		console.log(err);
	}
}

export async function stakingLockingRewards() {
	const account = await getAccount();
	if (account) {
		try {
			const result = await raiderStakingLockingContract.methods
				.userPendingRewards(account)
				.call();
			return ethers.utils.formatEther(result);
		} catch (err) {
			console.log(err);
			return "0";
		}
	} else {
		return "0"
	}

}

export async function stakedDailyEmissions() {
	try {
		const result = await raiderStakingLockingContract.methods
			.dailyEmissionsRate()
			.call();
		return ethers.utils.formatEther(result);
	} catch (err) {
		console.log(err);
		return "0";
	}
}

export async function stakedWeightedSupply() {
	try {
		const result = await raiderStakingLockingContract.methods
			.totalWeightedStakedSupply()
			.call();
		return ethers.utils.formatEther(result);
	} catch (err) {
		console.log(err);
	}
}

export async function fetchRawUserLockupDuration(): Promise<number | undefined> {
	const account = await getAccount();
	if (account) {
		try {
      const data = await raiderStakingLockingContract.methods
        .showLockTimeRemaining(account)
        .call();
      return data;
	  } catch (err) {
		  console.log(err);
	  }
  }
}


export async function fetchUserLockupDuration() {
	const data = await fetchRawUserLockupDuration();
	if (data != undefined) {
		const days = Math.floor((data * 100) / 60 / 60 / 24) / 100;
		return days;
	}
}

export async function createLockedStake(
	amount: string,
	duration: number,
	toast: (options: UseToastOptions) => string | number | undefined
) {
	try {
		const account = await getAccount();
		const result = await raiderStakingLockingContract.methods
			.createStake(ethers.utils.parseEther(amount), duration)
			.send({ from: account, maxPriorityFeePerGas: null,
			maxFeePerGas: null }, function (err: any, res: any) {
				if (err) {
					toast({
						title: "Couldn't create your stake...",
						description: err.message,
						status: "error",
						duration: 9000,
						position: "top",
						isClosable: true,
					});
					console.log(err);
				}
				console.log("Hash of the transaction: " + res);
			});
		return result;
	} catch (err) {
		console.log(err);
	}
}

export async function removeLockedStake(
	amount: string,
	toast: (options: UseToastOptions) => string | number | undefined
) {
	const account = await getAccount();
	if (!account) {
		throw "No account to unstake with!";
	}
	const result = await raiderStakingLockingContract.methods
		.removeStake(ethers.utils.parseEther(amount))
		.send({ from: account, maxPriorityFeePerGas: null,
		maxFeePerGas: null }, function (err: any, res: any) {
			if (err) {
				toast({
					title: "Couldn't remove your stake...",
					description: err.message,
					status: "error",
					duration: 9000,
					position: "top",
					isClosable: true,
				});
				throw err
			}
			console.log("Hash of the transaction: " + res);
		});
	return result;
}

export async function getLockedRewards(
	toast: (options: UseToastOptions) => string | number | undefined
) {
	try {
		const account = await getAccount();
		const result = await raiderStakingLockingContract.methods
			.getRewards()
			.send({ from: account, maxPriorityFeePerGas: null,
			maxFeePerGas: null }, function (err: any, res: any) {
				if (err) {
					toast({
						title: "Couldn't get your rewards...",
						description: err.message,
						status: "error",
						duration: 9000,
						position: "top",
						isClosable: true,
					});
					console.log(err);
				}
				console.log("Hash of the transaction: " + res);
			});
		return result;
	} catch (err) {
		console.log(err);
	}
}

// ---------- LP STAKING POOLS ----------

const poolResolver = (pool: string) => {
	if (pool === "AURUM/USDC") {
		return raiderStakingContract(process.env.NEXT_PUBLIC_STAKING_AURUMUSDC!);
	} else if (pool === "AURUM/MATIC") {
		return raiderStakingContract(process.env.NEXT_PUBLIC_STAKING_AURUMMATIC!);
	} else if (pool === "RAIDER/MATIC") {
		return raiderStakingContract(process.env.NEXT_PUBLIC_STAKING_RAIDERMATIC!);
	} else if (pool === "RAIDER/WETH") {
		return raiderStakingContract(process.env.NEXT_PUBLIC_STAKING_RAIDERETH!);
	} else {
		throw 'pool not defined';
	}
};

const tokenResolver = (token: string) => {
	if (token === "AURUM") {
		return aurumContract;
	} else if (token === "RAIDER") {
		return raiderContract;
	} else if (token === "AURUM/USDC") {
		return lpTokenContract(process.env.NEXT_PUBLIC_TOKEN_AURUMUSDC!);
	} else if (token === "AURUM/MATIC") {
		return lpTokenContract(process.env.NEXT_PUBLIC_TOKEN_AURUMMATIC!);
	} else if (token === "RAIDER/MATIC") {
		return lpTokenContract(process.env.NEXT_PUBLIC_TOKEN_RAIDERMATIC!);
	} else if (token === "RAIDER/WETH") {
		return lpTokenContract(process.env.NEXT_PUBLIC_TOKEN_RAIDERETH!);
	} else if (token === "GRIMWEED") {
		return grimweedContract;
	} else if (token === "NEWT") {
		return newtContract;
	} else if (token === "MHP") {
		return mhpContract;
	} else if (token === "BHP") {
		return bhpContract;
	} else if (token === "MDP") {
		return mdpContract;
	} else if (token === "BDP") {
		return bdpContract;
	}
	else if (token === "SPOREBARK") {
		return sporebarkContract
	} else {
		throw `${token} invalid token`;
	}
};

export async function lpTokenBalance(poolName: string) {
	const account = await getAccount();
	if (account) {
		const token = tokenResolver(poolName);
		try {
			const result = await token.methods.balanceOf(account).call();
			return ethers.utils.formatEther(result);
		} catch (err) {
			console.log(err)
		}
	}
}

export async function stakingLPBalance(poolName: string) {
	const account = await getAccount();
	if (account) {
		const pool = poolResolver(poolName);
		try {
			const result = await pool.methods.addressStakedBalance(account).call();
			return ethers.utils.formatEther(result);
		} catch (err) {
			console.log(err);
		}
	}
}

export async function totalLPStakedSupply(poolName: string) {
	const pool = poolResolver(poolName);

	try {
		const result = await pool.methods.totalStakedSupply().call();
		return ethers.utils.formatEther(result);
	} catch (err) {
		console.log(err);
	}
}

export async function stakingLPRewards(poolName: string) {
	const account = await getAccount();
	if (account) {
		const pool = poolResolver(poolName);
		try {
			const result = await pool.methods.userPendingRewards(account).call();
			return ethers.utils.formatEther(result);
		} catch (err) {
			console.log(err);
			return "0"
		}
	} else {
		return "0"
	}
}

export async function lpDailyEmissions(poolName: string) {
	const pool = poolResolver(poolName);
	try {
		const result = await pool.methods.dailyEmissionsRate().call();
		return ethers.utils.formatEther(result);
	} catch (err) {
		console.log(err);
	}
}

export async function createLPStake(
	amount: string,
	poolName: string,
	toast: (options: UseToastOptions) => string | number | undefined
) {
	const pool = poolResolver(poolName);
	try {
		const account = await getAccount();
		const result = await pool.methods
			.createStake(ethers.utils.parseEther(amount))
			.send({ from: account, maxPriorityFeePerGas: null,
			maxFeePerGas: null }, function (err: any, res: any) {
				if (err) {
					toast({
						title: "Couldn't create a stake...",
						description: err.message,
						status: "error",
						duration: 9000,
						position: "top",
						isClosable: true,
					});
					console.log(err);
				}
				console.log("Hash of the transaction: " + res);
			});
		return result;
	} catch (err) {
		console.log(err);
	}
}

export async function removeLPStake(
	amount: string,
	poolName: string,
	toast: (options: UseToastOptions) => string | number | undefined
) {
	const pool = poolResolver(poolName);
	try {
		const account = await getAccount();
		const result = await pool.methods
			.removeStake(ethers.utils.parseEther(amount))
			.send({ from: account, maxPriorityFeePerGas: null,
			maxFeePerGas: null }, function (err: any, res: any) {
				if (err) {
					toast({
						title: "Couldn't remove your stake...",
						description: err.message,
						status: "error",
						duration: 9000,
						position: "top",
						isClosable: true,
					});
					console.log(err);
				}
				console.log("Hash of the transaction: " + res);
			});
		return result;
	} catch (err) {
		console.log(err);
	}
}

export async function getLPRewards(
	poolName: string,
	toast: (options: UseToastOptions) => string | number | undefined
) {
	const pool = poolResolver(poolName);
	try {
		const account = await getAccount();
		const result = await pool.methods
			.getRewards()
			.send({ from: account, maxPriorityFeePerGas: null,
			maxFeePerGas: null }, function (err: any, res: any) {
				if (err) {
					toast({
						title: "Couldn't get rewards...",
						description: err.message,
						status: "error",
						duration: 9000,
						position: "top",
						isClosable: true,
					});
					console.log(err);
				}
				console.log("Hash of the transaction: " + res);
			});
		return result;
	} catch (err) {
		console.log(err);
	}
}

// ---------- OLD LP STAKING POOLS ----------

const oldPoolResolver = (pool: string) => {
	if (pool === "AURUM/USDC") {
		return raiderStakingContract(process.env.NEXT_PUBLIC_OLD_STAKING_AURUMUSDC!);
	} else if (pool === "AURUM/MATIC") {
		return raiderStakingContract(
			process.env.NEXT_PUBLIC_OLD_STAKING_AURUMMATIC!
		);
	} else if (pool === "RAIDER/MATIC") {
		return raiderStakingContract(
			process.env.NEXT_PUBLIC_OLD_STAKING_RAIDERMATIC!
		);
	} else if (pool === "RAIDER/WETH") {
		return raiderStakingContract(process.env.NEXT_PUBLIC_OLD_STAKING_RAIDERETH!);
	} else {
		throw 'invalid';
	}
};

export async function oldStakingLPBalance(poolName: string) {
	const account = await getAccount();
	const pool = oldPoolResolver(poolName);

	try {
		const result = await pool.methods.addressStakedBalance(account).call();
		return ethers.utils.formatEther(result);
	} catch (err) {
		return "0";
	}
}

export async function removeOldLPStake(
	amount: string,
	poolName: string,
	toast: (options: UseToastOptions) => string | number | undefined
) {
	const pool = oldPoolResolver(poolName);
	try {
		const account = await getAccount();
		const result = await pool.methods
			.removeStake(ethers.utils.parseEther(amount))
			.send({ from: account, maxPriorityFeePerGas: null,
			maxFeePerGas: null }, function (err: any, res: any) {
				if (err) {
					toast({
						title: "Couldn't remove your stake...",
						description: err.message,
						status: "error",
						duration: 9000,
						position: "top",
						isClosable: true,
					});
					console.log(err);
				}
				console.log("Hash of the transaction: " + res);
			});
		return result;
	} catch (err) {
		console.log(err);
	}
}
