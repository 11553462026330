import { Box, Flex, Spacer } from "@chakra-ui/react";
import { GiBowman, GiWallet } from "react-icons/gi";
import {RiOmega} from "react-icons/ri";
import {GiRoundBottomFlask} from "react-icons/gi";
import { BsBank2 } from "react-icons/bs";
import { BiRun } from "react-icons/bi";
import { NavItem } from "./NavItem";

const MobileNavMenu = (props: { isOpen?: boolean }) => {
	const { isOpen } = props;
	return (
		<Flex
			hidden={!isOpen}
			as="nav"
			direction="column"
			bg="blue.600"
			position="fixed"
			height="calc(100vh - 4rem)"
			top="16"
			insetX="0"
			zIndex={10}
			w="full"
		>
			<Box px="4">
				<NavItem.Mobile label="Wallet" href="/" />
				<NavItem.Mobile label="Quests" href="/quests" />
				<NavItem.Mobile label="Alchemy" href="/alchemy" />
				<NavItem.Mobile label="Staking" href="/staking" />
				<NavItem.Mobile label="Bonds" href="https://pro.olympusdao.finance/#/partners/Crypto%20Raiders" target="_blank"/>
				<NavItem.Mobile label="Mob Mountain" href="/mob_mountain" />

				{/* 
					<NavItem.Mobile label="Recruiting" href="/recruiting" />
					 <NavItem.Mobile label="Polygon Migration" href="/migrate" />
        <NavItem.Mobile
          target="_blank"
          label="Secondary Market Verification"
          href="/before-you-buy"
        /> */}
			</Box>
		</Flex>
	);
};

const DesktopNavMenu = () => (
	<Flex spacing="3" flex="1" display={{ base: "none", lg: "flex" }}>
		<NavItem.Desktop icon={<GiWallet />} label="Wallet" href="/" />
		<NavItem.Desktop icon={<GiBowman />} label="Quests" href="/quests" />
		<NavItem.Desktop icon={<GiRoundBottomFlask />} label="Alchemy" href="/alchemy" />
		<NavItem.Desktop icon={<BsBank2 />} label="Staking" href="/staking" />
		<NavItem.Desktop icon={<RiOmega />} label="Bonds" href="https://pro.olympusdao.finance/#/partners/Crypto%20Raiders" target="_blank"/>
		{/*<NavItem.Desktop icon={<BiBeer />} label="Recruiting" href="/recruiting" /> */}
		<NavItem.Desktop icon={<BiRun />} label="Mob Mountain" href="/mob_mountain" />

		<Spacer />

		{/* <NavItem.Desktop
      icon={<TiArrowSync />}
      label="Polygon Migration"
      href="/migrate"
    />
    <NavItem.Desktop
      icon={<MdVerifiedUser />}
      target="_blank"
      label="Secondary Market Verification"
      href="/before-you-buy"
    /> */}
	</Flex>
);

export const NavMenu = {
	Mobile: MobileNavMenu,
	Desktop: DesktopNavMenu,
};
