import { Icon, Box } from "@chakra-ui/react";
import * as React from "react";
import { RiSwordFill } from "react-icons/ri";

export const Logo = () => {
	return (
		<Box pr={6}>
			<Icon boxSize="32px" as={RiSwordFill} />
		</Box>
	);
};
