import { Box, HStack } from "@chakra-ui/react";
import * as React from "react";
import NextLink from "next/link";

interface NavItemProps {
	href?: string;
	active?: boolean;
	target?: string;
	label: string;
}

interface DesktopNavItemProps extends NavItemProps {
	icon: React.ReactNode;
}

const DesktopNavItem = (props: DesktopNavItemProps) => {
	const { icon, label, href = "#", active, target } = props;
	if (!target)
		return (
			<NextLink href={href} passHref={true}>
				<HStack
					as="a"
					aria-current={active ? "page" : undefined}
					spacing="2"
					px="3"
					py="2"
					rounded="md"
					transition="all 0.2s"
					color="gray.200"
					_hover={{ bg: "whiteAlpha.200", cursor: "pointer" }}
					_activeLink={{ bg: "blackAlpha.300", color: "white" }}
				>
					{icon && (
						<Box aria-hidden fontSize="md">
							{icon}
						</Box>
					)}
					<Box fontWeight="semibold">{label}</Box>
				</HStack>
			</NextLink>
		);
	return (
		<a href={href} target={target} rel="noopener noreferrer">
			<HStack
				aria-current={active ? "page" : undefined}
				spacing="2"
				px="3"
				py="2"
				rounded="md"
				transition="all 0.2s"
				color="gray.200"
				_hover={{ bg: "whiteAlpha.200", cursor: "pointer" }}
				_activeLink={{ bg: "blackAlpha.300", color: "white" }}
			>
				{icon && (
					<Box aria-hidden fontSize="md">
						{icon}
					</Box>
				)}
				<Box fontWeight="semibold">{label}</Box>
			</HStack>
		</a>
	);
};

const MobileNavItem = (props: NavItemProps) => {
	const { label, href = "#", active } = props;
	return (
		<NextLink href={href}>
			<Box
				as="a"
				display="block"
				px="3"
				py="3"
				rounded="md"
				fontWeight="semibold"
				aria-current={active ? "page" : undefined}
				_hover={{ bg: "whiteAlpha.200", cursor: "pointer" }}
				_activeLink={{ bg: "blackAlpha.300", color: "white" }}
			>
				{label}
			</Box>
		</NextLink>
	);
};

export const NavItem = {
	Desktop: DesktopNavItem,
	Mobile: MobileNavItem,
};
