import { Box, HStack, Stack, Text, useColorModeValue } from '@chakra-ui/react'
import * as React from 'react'

export const Banner = (props: any) => {
  const { message, display } = props;
  const banner = (
    <Box as="section">
    <Stack
      direction={{ base: 'column', sm: 'row' }}
      justifyContent="center"
      alignItems="center"
      py="3"
      px={{ base: '3', md: '6', lg: '8' }}
      color="white"
      bg={useColorModeValue('red.600', 'red.400')}
    >
      <HStack spacing="3">
        <Text fontWeight="medium" marginEnd="2">
          {message}
        </Text>
      </HStack>
    </Stack>
  </Box>
  )
  if (display) {
    return banner;
  }
  return <></>
}