/* eslint-disable no-undef */
import {
	Avatar,
	Box,
	Flex,
	HStack,
	Menu,
	MenuItem,
	MenuList,
	Text,
	useMenuButton,
	UseMenuButtonProps,
	useColorModeValue as mode,
} from "@chakra-ui/react";
// import { logOut } from "../../../util/auth/authenticateUtils";

import gravatar from "gravatar";
import { Web3Provider } from "@ethersproject/providers";
import { useWeb3React } from "@web3-react/core";
const UserAvatar = () => {
	const context = useWeb3React<Web3Provider>();
	const { account } = context;

	return (
		<Avatar
			size="sm"
			src={gravatar.url(account || "", { s: "80", d: "retro" })}
			name={account || ""}
		/>
	);
};

const ProfileMenuButton = (props: UseMenuButtonProps) => {
	const buttonProps = useMenuButton(props);
	return (
		<Flex
			{...buttonProps}
			as="button"
			flexShrink={0}
			rounded="full"
			outline="0"
			_focus={{ shadow: "outline" }}
		>
			<Box srOnly>Open user menu</Box>
			<UserAvatar />
		</Flex>
	);
};

export const ProfileDropdown = (props: {active: boolean, mm_func: Function, wc_func: Function, deactivate: Function}) => {
	return (
		<Menu>
			<ProfileMenuButton />
			<MenuList
				rounded="md"
				shadow="lg"
				py="1"
				color={mode("gray.600", "inherit")}
				fontSize="sm"
			>
				<HStack px="3" py="4">
					<UserAvatar />
					<Box lineHeight="1">
						<Text fontWeight="semibold">Metamask User</Text>
						{/* <Text mt="1" fontSize="xs" color="gray.500">
							{`${String(account).substr(0, 4)}…${String(account).substr(
								42 - 4
							)}`}
						</Text> */}
					</Box>
				</HStack>
						{!props.active && (
							<span>
								<MenuItem  onClick={() => { props.wc_func() }} marginRight={2}>Wallet Connect</MenuItem>
								<MenuItem  onClick={() => {props.mm_func()}}>Metamask</MenuItem>
							</span>

						)}
						{props.active && (
							<MenuItem onClick={() => {props.deactivate()}}>Disconnect</MenuItem>
						)}

				{/* <MenuItem fontWeight="medium">Feedback & Support</MenuItem>
				<MenuItem fontWeight="medium">Account Settings</MenuItem> */}
				<MenuItem
					fontWeight="medium"
					color={mode("red.500", "red.300")}
					// onClick={() => {
					// 	logOut(toast);
					// }}
				>
					Sign out
				</MenuItem>
			</MenuList>
		</Menu>
	);
};
