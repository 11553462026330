import { useBoolean, useBreakpointValue } from "@chakra-ui/react";
import { useEffect } from "react";

export const useMobileMenuState = () => {
	const [isMenuOpen, actions] = useBoolean();
	/**
	 * Scenario: Menu is open on mobile, and user resizes to desktop/tablet viewport.
	 * Result: We'll close the menu
	 */
	const isMobileBreakpoint = useBreakpointValue({ base: true, lg: false });

	useEffect(() => {
		if (isMobileBreakpoint == false) {
			actions.off();
		}
	}, [isMobileBreakpoint, actions]);

	return { isMenuOpen, ...actions };
};
